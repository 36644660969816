import TextField from '@app/src/components/Ui/TextField'
import { useAccount } from '@app/src/context/AccountContext'
import { languagesWeHaveTranslationFilesFor } from '@app/src/i18n'
import { Autocomplete, Grid } from '@mui/material'
import { useTheme } from '@mui/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Control, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface LanguageInfo {
  value: string
  label: string
  flagCode: string
}

interface LanguageSelectorProps {
  control: Control
}

const TOTAL_LANGUAGE_OPTIONS: LanguageInfo[] = [
  { value: 'en-US', label: 'English', flagCode: 'GB' },
  { value: 'sv-SE', label: 'Svenska', flagCode: 'SE' },
  { value: 'es-ES', label: 'Español', flagCode: 'ES' },
  { value: 'de', label: 'Deutsch', flagCode: 'DE' },
  { value: 'zh-CN', label: '中文 (简体)', flagCode: 'CN' },
]

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ control }) => {
  const { availableLanguages } = useFlags()
  const { account } = useAccount()
  const { formatMessage } = useIntl()
  const { spacing } = useTheme()

  const availableLanguageOptions = TOTAL_LANGUAGE_OPTIONS.filter(
    option => languagesWeHaveTranslationFilesFor.includes(option.value) && availableLanguages.includes(option.value),
  )
  const user = account?.user
  const hideLanguageSelector =
    availableLanguageOptions.length === 1 && user?.culture === availableLanguageOptions[0].value

  if (hideLanguageSelector) {
    return null
  }

  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="culture"
        defaultValue={user?.culture}
        render={({ onChange, value }) => (
          <Autocomplete
            fullWidth
            options={availableLanguageOptions}
            getOptionLabel={option => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={availableLanguageOptions.find(option => option.value === value)}
            onChange={(_, newValue) => onChange(newValue ? newValue.value : null)}
            disableClearable
            renderOption={(props, option) => (
              <li {...props}>
                <ReactCountryFlag
                  countryCode={option.flagCode}
                  svg
                  style={{ fontSize: '2em', marginRight: spacing(2) }}
                />
                {option.label}
              </li>
            )}
            renderInput={params => {
              const selectedOption = availableLanguageOptions.find(option => option.value === value)
              return (
                <TextField
                  {...params}
                  hoveringLabel
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <ReactCountryFlag
                        countryCode={selectedOption?.flagCode}
                        svg
                        style={{ fontSize: '2em', marginRight: spacing(1), marginLeft: spacing(1) }}
                      />
                    ),
                  }}
                  label={formatMessage({ id: 'general.language' })}
                />
              )
            }}
          />
        )}
      />
    </Grid>
  )
}

export default LanguageSelector
